import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../data/Store";
import axios from "axios";
import { postsUrl } from "../data/Endpoints";
import _ from "lodash";
import { Article } from "./Article";

export const Articles = () => {
	const { store, setStore } = useContext(Context);
	const { feed, setFeed } = useContext(Context);
	const [loading, setLoading] = useState(null);
	const [latest, setLatest] = useState([]);
	const [popular, setPopular] = useState([]);

	const getPosts = useCallback(async () => {
		await axios.get(postsUrl).then(({ data }) => {
			setLatest(data.filter(post => post.isPopular === false));
			setPopular(data.filter(post => post.isPopular === true));
		});
	}, []);

	useEffect(() => {
		getPosts();
	}, []);

	return (
		<>
			<div className="dashboard-feed__articles-container">
				{feed === "latest" && _.orderBy(latest, ["publicationTime"], ["desc"]).map(post => {
					return <Article key={post.id} post={post} />;
				})}
				{feed === "popular" && _.orderBy(popular, ["publicationTime"], ["desc"]).map(post => {
					return <Article key={post.id} post={post} />;
				})}
			</div>
		</>
	);
};
