export const Widgets = () => {
	return (
		<>
			<div className="dashboard-feed__widgets">
				<div className="is-sticky">
					<div className="dashboard-feed__widgets-item">
						<span className="placeholder-title"></span>
						<span className="placeholder-text"></span>
					</div>
					<button className="dashboard-feed__widgets-button">
						<span className="placeholder-text"></span>
						<span className="placeholder-icon"></span>
					</button>
				</div>
			</div>
		</>
	);
};
