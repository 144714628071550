import { Articles } from "./Articles";
import { Filters } from "./Filters";
import { Widgets } from "./Widgets";

export const Feed = () => {
	return (
		<>
			<section className="dashboard-feed">
				<div className="dashboard-feed__articles">
					<Filters />
					<Articles/>
				</div>
				<Widgets />
			</section>
		</>
	);
};
