import logo from "../assets/logo.svg";
import logo_mobile from "../assets/logo-mobile.svg";

const sections = ["Trade ideas", "Strategies", "Signals", "Events", "Recordings"];

export const Aside = () => {
	return (
		<>
			<aside className="dashboard-aside dashboard-aside--brand-main-color">
				<div className="is-sticky">
					<div className="dashboard-aside__logo">
						<a href="/">
							<img src={logo} alt="FXStreet Premium" />
						</a>
					</div>
					<ul className="dashboard-aside__menu dashboard-aside__menu--unstyled">
						{sections.map(section => {
							return (
								<li className="dashboard-aside__menu-item" key={section}>
									<span className="placeholder"></span>
								</li>
							);
						})}
					</ul>
				</div>
			</aside>
			<header className="dashboard-header-mobile ">
				<a href="/" className="logo">
					<img src={logo_mobile} alt="FXStreet Premium" />
				</a>
				<div className="profile">
					<span className="placeholder"></span>
					<span className="placeholder"></span>
				</div>
			</header>
		</>
	);
};
