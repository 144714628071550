import { useState } from "react";
import "./App.css";
import { Aside } from "./components/Aside";
import { Feed } from "./components/Feed";
import Layout from "./components/Layout";
import Main from "./components/Main";
import { Nav } from "./components/Nav";
import { Context, Store } from "./data/Store";


function App() {
	const [store, setStore] = useState(Store);
	const [feed, setFeed] = useState("latest");
	

	return (
		<>
			<Context.Provider value={{ store, setStore, feed, setFeed }}>
				<Layout>
					<Aside />
					<Main>
						<Nav />
						<Feed/>
					</Main>
				</Layout>
			</Context.Provider>
		</>
	);
}

export default App;
