import DOMPurify from "dompurify";

export const Article = ({ post }) => {
	const getDate = date => {
		const newDate = new Date(date);
		const month = newDate.toLocaleString("en-US", { month: "short" });
		const day = newDate.getUTCDate();
		const hour = newDate.getUTCHours();
		const minutes = newDate.getUTCMinutes();
		return `${month} ${day}, ${hour}:${minutes < 10 ? "0" + minutes : minutes}`;
	};

	// To do: both set functions can be refactored into one
	const setLiked = ({ currentTarget }) => {
		// In a real world escenario, we need to get the article id and make a Post to the user profile data
		const text = currentTarget.querySelector(".text");
		const icon = currentTarget.querySelector(".icon");

		if (currentTarget.classList.contains("clicked")) {
			currentTarget.classList.remove("clicked");
			text.innerHTML = "Like";
			icon.innerHTML = "favorite_border";
			return;
		}

		currentTarget.classList.add("clicked");
		text.innerHTML = "Liked!";
		icon.innerHTML = "favorite";
	};

	const setSaved = ({ currentTarget }) => {
		const text = currentTarget.querySelector(".text");
		const icon = currentTarget.querySelector(".icon");

		if (currentTarget.classList.contains("clicked")) {
			currentTarget.classList.remove("clicked");
			text.innerHTML = "Save";
			icon.innerHTML = "bookmark_border";
			return;
		}

		currentTarget.classList.add("clicked");
		text.innerHTML = "Saved!";
		icon.innerHTML = "bookmark";
	};

	const revealMenu = ({ currentTarget }) => {
		if (currentTarget.classList.contains("open")) {
			currentTarget.classList.remove("open");
			return;
		}
		setTimeout(() => {
			document.addEventListener(
				"click",
				function () {
					currentTarget.classList.remove("open");
				},
				{ once: true }
			);
		}, 250);

		currentTarget.classList.add("open");
	};

	const revealHideForm = id => {
		const element = document.getElementById(id);
		const target = element.querySelector(".hide-article-form");

		if (target.classList.contains("open")) {
			target.classList.remove("open");
			return;
		}
		target.classList.add("open");
		return;
	};

	return (
		<>
			<article id={post.id} key={post.id} className="dashboard-feed__articles-single">
				<div className="article__meta">
					<div className="source-feed">
						<i className="far fa-file blue file-icon"></i>
						<span className="feed blue">{post.feed}</span>
						<i className="fas fa-caret-right light-blue separator"></i>
						<span className="subfeed light-blue">{post.subFeed}</span>
					</div>
					<div className="published">
						<i className="far fa-clock clock-icon"></i>
						<span className="date">{getDate(post.publicationTime)}</span>
					</div>
				</div>
				<div className="article__title">
					<div className="author-picture">
						<img src={post.author.imageUrl} alt={post.author.name} />
					</div>
					<div className="article-intro">
						<div className="author">
							<span className="author-name">{post.author.name}</span> | <span className="company-name">{post.author.companyName}</span>
						</div>
						<h3 className="title">
							<a href="#">{post.title}</a>
						</h3>
					</div>
				</div>
				<div className="article__excerpt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}></div>
				{post.imageUrl && (
					<a href="#">
						<img className="article__featured-image" src={post.imageUrl} alt="" />
					</a>
				)}
				<div className="article__actions">
					<div className="like" onClick={event => setLiked(event)}>
						<i className="material-icons material-symbols-outlined icon">favorite_border</i>
						<span className="text">Like</span>
					</div>
					<div className="save" onClick={event => setSaved(event)}>
						<i className="material-icons material-symbols-outlined icon">bookmark_border</i>
						<span className="text">Save</span>
					</div>
					<div className="more" onClick={event => revealMenu(event)}>
						<i className="material-icons material-symbols-outlined icon icon-more">more_horiz</i>
						<div className="more__menu" onClick={event => event.stopPropagation()}>
							<ul>
								<li className="hide-sub-menu" onClick={() => revealHideForm(post.id)}>
									<i className="material-icons menu-icon">visibility_off</i> Hide
									<div className="hide-article-form" onClick={event => event.stopPropagation()}>
										<div className="hide-article-form__header">
											<i className="material-icons hide-article-form__header__icon" onClick={() => revealHideForm(post.id)}>
												arrow_back
											</i>
											<span>Tell us why:</span>
										</div>
										<form action="">
											<fieldset>
												<div>
													<input type="radio" id={"author-" + post.id} name="why-hide" value="I'm not interested in this author" />
													<label htmlFor={"author-" + post.id}>
														<i className="material-icons radio">radio_button_off</i>I'm not interested in this author
													</label>
												</div>
												<div>
													<input type="radio" id={"topic-" + post.id} name="why-hide" value="I'm not interested in this topic" />
													<label htmlFor={"topic-" + post.id}>
														<i className="material-icons radio">radio_button_off</i>Im not interested in this topic
													</label>
												</div>
												<div>
													<input type="radio" id={"too-many-topic-" + post.id} name="why-hide" value="I've seen too many posts on this topic" />
													<label htmlFor={"too-many-topic-" + post.id}>
														<i className="material-icons radio">radio_button_off</i>I've seen too many posts on this topic
													</label>
												</div>
												<div>
													<input type="radio" id={"incorrect-" + post.id} name="why-hide" value="The information is incorrect" />
													<label htmlFor={"incorrect-" + post.id}>
														<i className="material-icons radio">radio_button_off</i>The information is incorrect
													</label>
												</div>
												<div>
													<input type="radio" id={"seen-" + post.id} name="why-hide" value="I've seen this post before" />
													<label htmlFor={"seen-" + post.id}>
														<i className="material-icons radio">radio_button_off</i>I've seen this post before
													</label>
												</div>
												<div>
													<input type="radio" id={"other-" + post.id} name="why-hide" value="Other reasons" />
													<label htmlFor={"other-" + post.id}>
														<i className="material-icons radio">radio_button_off</i>Other reasons
													</label>
												</div>
											</fieldset>
											<button disabled>Hide content</button>
										</form>
									</div>
								</li>

								<li>
									<i className="material-icons menu-icon">tune</i>Improve my feed
								</li>
							</ul>
						</div>
					</div>
				</div>
			</article>
		</>
	);
};
