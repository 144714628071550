export const Nav = () => {
	return (
		<>
			<nav className="dashboard-nav dashboard-nav--white is-sticky">
				<div className="dashboard-nav__backlink separator">
					<div className="dashboard-nav__backlink-container">
						<span className="placeholder-icon"></span>
						<span className="placeholder-text"></span>
					</div>
					<span className="placeholder-link-highlight"></span>
				</div>
				<div className="dashboard-nav__help separator">
					<span className="placeholder-icon"></span>
				</div>
				<div className="dashboard-nav__notification separator">
					<span className="placeholder-icon"></span>
				</div>
				<div className="dashboard-nav__profile">
					<span className="placeholder-icon"></span>
					<span className="placeholder-text"></span>
				</div>
			</nav>
		</>
	);
};
