import { useContext } from "react";
import { Context } from "../data/Store";

export const Filters = () => {
	const { feed, setFeed } = useContext(Context);

	return (
		<div className="filters">
			<div className="filters__by-relevance">
				<span className={feed === "latest" ? "latest highlight" : "latest"} onClick={() => setFeed("latest")}>
					Latest
				</span>
				<span className={feed === "popular" ? "popular highlight" : "popular"} onClick={() => setFeed("popular")}>
					Popular
				</span>
			</div>
			<div className="filters__by-type">
				<span className="show">Show:</span>
				<div className="select-container">
					<select className="type">
						<option value="All">All</option>
					</select>
					<i className="fa fa-angle-down type-icon"></i>
				</div>
				<div className="icon-filter-mobile">
					<i className="fas fa-filter"></i>
				</div>
			</div>
		</div>
	);
};
