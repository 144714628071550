import { createContext } from "react";

export const Context = createContext();

export const Store = {
	posts: {
		latest: [],
		popular: [],
	},
};

